<template>
  <v-card id="server-setting-card">
    <v-card-text>
      <vl-map
        v-if="!loading"
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true" 
        data-projection="EPSG:4326"
        style="height: 700px"
      >
        <vl-view
          :zoom.sync="zoom"
          :center.sync="center"
          :rotation.sync="rotation"
          :min-zoom="1"
        >
        </vl-view>

        <vl-layer-tile id="osm">
            <vl-source-osm></vl-source-osm>
        </vl-layer-tile>

        <vl-interaction-select :features.sync="selectedServers"
          @select="changeServer"
        >
          <template slot-scope="select">
          <vl-style-box>
            <vl-style-stroke color="#0082B3" :width="7"></vl-style-stroke>
            <vl-style-fill :color="[254, 178, 76, 0.7]"></vl-style-fill>
            <vl-style-circle :radius="15">
              <vl-style-stroke color="#0082B3" :width="7"></vl-style-stroke>
              <vl-style-fill :color="[254, 178, 76, 0.3]"></vl-style-fill>
            </vl-style-circle>
          </vl-style-box>


            <vl-overlay class="feature-popup" v-for="(server, idx) in select.features" :key="'info'+idx" :id="idx" :position="findPointOnSurface(server.geometry)" :auto-pan="true" :auto-pan-animation="{ duration: 300 }">
              <template>
                <v-card v-if="typeof server.properties.id_server == 'undefined'">
                  <v-card-text>
                    My Current Location
                  </v-card-text>
                </v-card>
                <v-card v-else>
                  <v-card-text>
                    <span class="font-weight-black">Name: </span>{{server.properties.name}}
                    <br>
                    <span class="font-weight-black">Status: </span>{{server.properties.active ? "Online" : "Out Line"}}
                    <br>
                    <span class="font-weight-black">Host By: </span>{{server.properties.host_by}}
                    <template v-if="server.properties.host_url != null">
                      <br>
                      <span class="font-weight-black">Host Url: </span> <a :href="server.properties.host_url" target="_black">Link</a>
                    </template>
                  </v-card-text>
                </v-card>
              </template>
            </vl-overlay>
          </template>
        </vl-interaction-select>

        <vl-geoloc @update:position="geolocPosition = $event">
          <template slot-scope="geoloc">
            <vl-feature v-if="geoloc.position" id="position-feature">
              <vl-geom-point :coordinates="geoloc.position"></vl-geom-point>
              <vl-style-box>
                <vl-style-icon src="/img/flag_blue.png" :scale="0.2" :anchor="[0.49, 0.98]" :size="[200, 200]"></vl-style-icon>
              </vl-style-box>
            </vl-feature>
          </template>
        </vl-geoloc>

        <vl-feature v-for="(server,idx) in serversMap" :key="'server-'+idx" :properties="server">
          <template>
            <vl-geom-point :coordinates="server.coordinates"></vl-geom-point>
            <vl-style-box>
              <vl-style-icon :src="server.active ? '/img/flag_green.png' : '/img/flag_red.png'" :scale="0.3" :anchor="[0.49, 0.98]" :size="[200, 200]"></vl-style-icon>
            </vl-style-box>
          </template>
        </vl-feature>

      </vl-map>
      <br>
      <ServerCreate
        ref="create"
        @change="getServers"
      >
      </ServerCreate>
      <ServerUpdate
        ref='update'
        @change="update"
      >
      </ServerUpdate>
      <ServerRegenerate
        ref='regenerate'
      >
      </ServerRegenerate>
      <v-btn
        color="primary"
        @click="openCreate"
      >
        <v-icon>
          {{icons.mdiPlus}}
        </v-icon>
        Create Server
      </v-btn>
      <br>
      <br>
      <template v-if="!loading">
        <v-tabs
          v-model="tab"
        >
        <v-tab v-for="server in servers" :key="`server-${server.id_server}`">
          {{server.name}}
        </v-tab>
      </v-tabs>
        <v-tabs-items
          v-model="tab"
        >
          <v-tab-item v-for="server in servers" :key="`server-tab-${server.id_server}`">
            <v-card flat>
              <v-card-text>
                <v-row class="ma-0 pa-0 text-center">
                  <v-btn
                    text
                    color="#4CAF50"
                    small
                    @click="openUpdate(server)"
                  >
                    <v-icon>
                      {{icons.mdiPencil}}
                    </v-icon>
                    Edit Server
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="#F44336"
                    small
                    @click="openRegenerate(server)"
                  >
                  <v-icon>
                    {{icons.mdiKeyVariant}}
                  </v-icon>
                    Regenerate Token
                  </v-btn>
                </v-row>
                <br>
                <ServerCharts
                  :id_server="server.id_server"
                ></ServerCharts>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <div v-else class="text-center">
        <v-progress-circular
          :size="70"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <br>
        <br>
        <div class="text-h5">Loading</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPlus, mdiPencil, mdiKeyVariant } from '@mdi/js'
import { onMounted, ref, computed } from '@vue/composition-api'
import ServerCreate from './ServerCreate.vue'
import ServerUpdate from './ServerUpdate.vue'
import ServerRegenerate from './ServerRegenerate.vue'
import ServerCharts from './ServerCharts.vue'
import axios from "axios"
import pointOnFeature from '@turf/point-on-feature'
import Circle from 'ol/geom/Circle'

export default {
  components: {
    ServerCreate,
    ServerUpdate,
    ServerRegenerate,
    ServerCharts
  },
  setup() {
    const GEOMETRY_TYPE = {
      POINT: 'Point',
      LINE_STRING: 'LineString',
      POLYGON: 'Polygon',
      MULTI_POINT: 'MultiPoint',
      MULTI_LINE_STRING: 'MultiLineString',
      MULTI_POLYGON: 'MultiPolygon',
      GEOMETRY_COLLECTION: 'GeometryCollection',
      CIRCLE: 'Circle',
    }

    const servers = ref([])
    const loading = ref(false)
    const zoom = ref(1.8933015308605634)
    const center = ref([10,20])
    const rotation = ref(0)
    const selectedServers = ref([])
    const tab = ref(null)
    
    const serversMap = computed(() => {
      var response = servers.value.filter(s => s.latitude != null && s.longitude != null)
      return response
    })

    onMounted(() => {
      getServers()
    })

    function findPointOnSurface (geom) {
      const simpleGeom = toSimpleGeom(geom)
      const pointFeature = pointOnFeature({
        type: simpleGeom.type || simpleGeom.getType(),
        coordinates: simpleGeom.coordinates || simpleGeom.getCoordinates(),
      })

      if (pointFeature && pointFeature.geometry) {
        return pointFeature.geometry.coordinates
      }
    }

    function toSimpleGeom (geom) {
      if (geom instanceof Circle) {
        geom = createPointGeom(geom.getCenter())
      }

      const type = geom.type || geom.getType()
      const complexTypes = [
        GEOMETRY_TYPE.GEOMETRY_COLLECTION,
      ]

      if (complexTypes.includes(type) === false) {
        return geom
      }

      return (geom.geometries || geom.getGeometries())[0]
    }

    function getServers(){
      loading.value = true;
      axios
      .get('/servers')
      .then(response => {
        servers.value = response.data;
        loading.value = false;
      })
      .catch(error => {
        console.log(error);
        loading.value = false;
      })
    }

    function openUpdate(server){
      this.$refs.update.showDialog(server);
    }

    function openCreate(){
      this.$refs.create.showDialog()
    }

    function update(data){
      var idx = servers.value.findIndex(s => s.id_server == data.id_server);
      if(idx >= 0) {
        servers.value[idx].name = data.name
        servers.value[idx].speedtest_interval = (data.speedtest * 60)
        servers.value[idx].latitude = data.latitude
        servers.value[idx].longitude = data.longitude
        servers.value[idx].coordinates = [data.longitude, data.latitude] 
        servers.value[idx].host_by = data.host_by
        servers.value[idx].host_url = data.host_url
      }
    }

    function openRegenerate(server){
      this.$refs.regenerate.showDialog(server.id_server);
    }

    function changeServer(item){
      var index = servers.value.findIndex(s => s.id_server == item.json.properties.id_server);
      if(index >= 0) tab.value = index; 
    }

    return {
      getServers,
      openCreate,
      openUpdate,
      openRegenerate,
      update,
      loading,
      servers,
      zoom,
      center,
      rotation,
      tab,
      selectedServers,
      serversMap,
      findPointOnSurface,
      changeServer,
      icons: {
        mdiPlus,
        mdiPencil,
        mdiKeyVariant
      }
    }
  }
}
</script>
