<template>
  <div>
    <v-dialog
      id="configuration-setting-card"
      v-model="show"
      max-width="70%"
      persistent
    >
      <v-card>
        <v-card-title>
          Create Server
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="6" class="align-self-center">
              <v-text-field
                v-model="name"
                label="Name"
                hide-details
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-subheader class="pl-0">
                SpeedTest Every {{speedtest}} {{plural()}}
              </v-subheader>
              <v-slider
                v-model="speedtest"
                step="1"
                min="1"
                max="24"
                hide-details
                dense
              ></v-slider>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                v-model="latitude"
                type="number"
                label="Latitude (Optional)"
                hide-details
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                v-model="longitude"
                type="number"
                label="Longitude (Optional)"
                hide-details
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                v-model="host_by"
                label="Host By"
                hide-details
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                v-model="host_url"
                label="Host URL"
                hide-details
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="errores.length > 0">
            <v-col cols="12">
              <div class="text-subtitle-2" style="color: #B71C1C">Errors:</div>
              <ul>
                <li v-for="(error, idx) in errores" :key="'error-'+idx" style="color: #B71C1C">{{error}}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="#4CAF50" @click="reset">Cancel</v-btn>
          <v-btn text color="#F44336" @click="save" :loading="saving">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="details"
      max-width="70%"
      persistent
    >
      <v-card>
        <v-card-title>
          Access Credentials for the New Server
        </v-card-title>
        <v-card-text>
          Id Server: {{data.id_server}}
          <br>
          Token: {{data.token}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="#4CAF50" @click="closeDetails">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import { ref } from '@vue/composition-api'
import axios from "axios"

export default {
  components: {
  },
  setup() {
    const details = ref(false)
    const show = ref(false)
    const name = ref('')
    const speedtest = ref(1)
    const latitude = ref(null)
    const longitude = ref(null)
    const host_by = ref("")
    const host_url = ref("")
    const errores = ref([])
    const saving = ref(false)
    const data = ref({
      id_server: -1,
      token: ''
    })

    function showDialog(){
      show.value = true
    }

    function reset(){
      show.value = false;
      name.value = ''
      speedtest.value = 1
      latitude.value = null
      longitude.value = null
      errores.value = []
      host_by.value = ''
      host_url.value = ''
    }

    function isValidUrl(urlString){
	    	var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
	      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
	      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
	      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
	      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
	      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
	    return !!urlPattern.test(urlString);
	  }

    function isValid(){
      errores.value = [];
      if(this.name == null || this.name.length < 3) errores.value.push('Name is Required and the minimum length is 3 characters');
      if(this.host_by == null || this.host_by.length < 3) errores.value.push('Host By is Required and the minimum length is 3 characters');
      if(this.host_url != null && this.host_url.length > 0){
        if(this.host_url.length < 3) errores.value.push('Host URL minimum length is 3 characters');
        else if(!this.isValidUrl(this.host_url)) errores.value.push('Host URL isn\'t a Valid URL');
      }
      return (errores.value.length == 0)
    }

    function save(){
      if(!this.isValid()) return;

      var me = this;
      saving.value = true;

      axios
      .post('/servers',{
        name: me.name,
        speedtest_interval: (me.speedtest * 60),
        latitude: (me.latitude == null || me.latitude.length == 0) ? null : me.latitude,
        longitude: (me.longitude == null || me.longitude.length == 0) ? null : me.longitude,
        host_by: me.host_by,
        host_url: me.host_url
      })
      .then(response => {
        closesave(response.data);
      })
      .catch(error => {
        console.log(error);
        me.reset();
      })
    }

    function closesave(datax){
      data.value = datax;
      saving.value = false;
      details.value = true;
      reset();
    }

    function closeDetails(){
      data.value = {
        id_server: -1,
        token: ''
      }
      details.value = false;
      this.$emit('change',null);
    }

    function plural(){
      return (speedtest.value == 1) ? 'Hour' : 'Hours'
    }

    return {
      reset,
      showDialog,
      plural,
      save,
      closeDetails,
      isValidUrl,
      isValid,
      show,
      name,
      speedtest,
      latitude,
      longitude,
      host_by,
      host_url,
      errores,
      saving,
      details,
      data
    }
  },
}
</script>
