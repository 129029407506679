<template>
  <div>
    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular
            :size="70"
            :width="4"
            color="primary"
            indeterminate
        ></v-progress-circular>
        <br>
        <br>
        <div class="text-h5">Loading</div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-card>
          <v-card-text class="text-center">
            Uptime:
            <h2 class="text-center">
              <ICountUp
                :delay="1000"
                :endVal="charts.uptime"
                :options="countUpPercentageOptions"
              />
            </h2>
          </v-card-text>
        </v-card>        
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-card>
          <v-card-text class="text-center">
            Ping:
            <h2 class="text-center">
              <ICountUp
                :delay="1000"
                :endVal="charts.ping"
                :options="countUpPingOptions"
              />
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-card>
          <v-card-text class="text-center">
            Download Speed:
            <h2 class="text-center">
              <ICountUp
                :delay="1000"
                :endVal="charts.download_speedtest"
                :options="countUpSpeedOptions"
              />
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="3" lg="3">
        <v-card>
          <v-card-text class="text-center">
            Upload Speed:
            <h2 class="text-center">
              <ICountUp
                :delay="1000"
                :endVal="charts.upload_speedtest"
                :options="countUpSpeedOptions"
              />
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <div class="text-subtitle-1 text-center">Uptime %</div>
        <vue-apex-charts
          :options="chartOptionsPercentage"
          :series="charts.uptime_chart"
          height="400"
        ></vue-apex-charts>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <div class="text-subtitle-1 text-center">Ping ms</div>
        <vue-apex-charts
          :options="chartOptions"
          :series="charts.ping_chart"
          height="400"
        ></vue-apex-charts>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <div class="text-subtitle-1 text-center">Download Speed Mbps</div>
        <vue-apex-charts
          :options="chartOptions"
          :series="charts.download_chart"
          height="400"
        ></vue-apex-charts>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <div class="text-subtitle-1 text-center">Upload Speed Mbps</div>
        <vue-apex-charts
          :options="chartOptions"
          :series="charts.upload_chart"
          height="400"
        ></vue-apex-charts>
      </v-col>
    </v-row>
  </div>
  
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from "axios"
import moment from "moment"
import ICountUp from 'vue-countup-v2';
import { onMounted, ref } from '@vue/composition-api'

export default {
  components: {
    ICountUp,
    VueApexCharts,
  },
  props: {
    id_server: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const loading = ref(false)
    const charts = ref(
      {
        download_speedtest: 0,
        upload_speedtest: 0,
        ping: 0,
        uptime: 0,
        download_chart: [
          {
            name: "Download Speed",
            data: []
          }
        ],
        upload_chart: [
          {
            name: "Upload Speed",
            data: []
          }
        ],
        ping_chart: [
          {
            name: "Ping",
            data: []
          }
        ],
        uptime_chart: [
          {
            name: "Uptime",
            data: []
          }
        ]
      }
    )
    
    onMounted(() => {
      loading.value = true;
      var from = moment().subtract(7,'day').utc().format('yyyy-MM-DD HH:mm:ss')
      var to = moment().utc().format('yyyy-MM-DD HH:mm:ss')
      
      axios
      .get(`/Servers/report?id_server=${props.id_server}&from=${from}&to=${to}`)
      .then(response => {
        loading.value = false;
        charts.value = response.data;
      })
      .catch(error => {
        console.log(error);
        loading.value = false;

      })
    })

    const chartOptionsPercentage = {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(2)+'%';
          },
        }/*,
        title: {
          text: 'Price'
        },
        */
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: false,
        x: {
          format: 'yyyy-MM-dd HH:mm'
        },
        y: {
          formatter: function (val) {
            return val.toFixed(2)+'%';
          }
        }
      }
    }

    const chartOptions = {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toFixed(2)
          },
        }/*,
        title: {
          text: 'Price'
        },
        */
      },
      xaxis: {
        type: 'datetime',
      },
      tooltip: {
        shared: false,
        x: {
          format: 'yyyy-MM-dd HH:mm'
        },
        y: {
          formatter: function (val) {
            return val.toFixed(2)
          }
        }
      }
    }

    const countUpPercentageOptions = {
      decimalPlaces: 2,
      decimal:'.',
      suffix:'%'
    }
    
    const countUpPingOptions = {
      decimalPlaces: 2,
      decimal:'.',
      suffix:' ms'
    }

    const countUpSpeedOptions = {
      decimalPlaces: 2,
      decimal:'.',
      suffix:' Mbps'
    }

    return {
      loading,
      chartOptions,
      chartOptionsPercentage,
      charts,
      countUpPercentageOptions,
      countUpPingOptions,
      countUpSpeedOptions
    }
  }
}
</script>
