<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="70%"
  >
    <v-card>
      <v-card-title>
        Regenerate Token
      </v-card-title>
      <v-card-text v-if="loading" class="text-center">
        <v-progress-circular
          :size="70"
          :width="4"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <br>
        <br>
        <div class="text-h5">Loading</div>
      </v-card-text>
      <v-card-text v-else>
        Token: {{token}}
      </v-card-text>
      <v-card-actions v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn text @click="close" color="#4CAF50">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from "axios"

export default {
  components: {
  },
  setup() {
    const loading = ref(false)
    const show = ref(false)
    const token = ref('')

    function showDialog(id_server){
      loading.value = true;
      show.value = true;

      var me = this;

      axios
      .put(`/Servers/regenerate/${id_server}`)
      .then(response => {
        token.value = response.data;
        loading.value = false;
      })
      .catch(error => {
        loading.value = false;
        me.show.value = false;
        console.log(error);
      })
    }

    function close(){
      show.value = false;
    }

    return{
      close,
      showDialog,
      loading,
      show,
      token
    }
  }
}
</script>